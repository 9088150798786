<template>
  <div class="wordscard-container layout-border">
    <WordImgGroupCards
      :wordCardList="wordCardList"
      :titleInfo="titleInfo"
      @changeTheImg="changeTheImg"
      :buttonList="buttonList"
      :lessonNum="53"
    />
  </div>
</template>
<script>
import WordImgGroupCards from "@/components/Course/CoursePage/WordImgGroupCards";

export default {
  components: {
    WordImgGroupCards,
  },
  data() {
    return {
      titleInfo:{
        hanzi:"我(不)认识……",
        pinyin:"Wǒ (bù) rènshi …"
      },
      buttonList: [
        {
          image: require("@/assets/img/16-GAMES/G62-vocab-cards/lesson-54/menu-1.svg"),
          isCircle: true,
          startNumberRange: 1,
          endNumberRange: 1,
        },
        {
          image: require("@/assets/img/16-GAMES/G62-vocab-cards/lesson-54/menu-2.svg"),
          isCircle: true,
          startNumberRange: 2,
          endNumberRange: 2,
        },
      ],
      wordCardList: [
        [
          {
            image: require(`@/assets/img/16-GAMES/G62-vocab-cards/lesson-54/card-1-1.svg`),
            imgShow: require(`@/assets/img/16-GAMES/G62-vocab-cards/lesson-54/card-1-2.svg`),
            Showing:false,
          },
          {
            image: require(`@/assets/img/16-GAMES/G62-vocab-cards/lesson-54/card-2-1.svg`),
            imgShow: require(`@/assets/img/16-GAMES/G62-vocab-cards/lesson-54/card-2-2.svg`),
            Showing:false,
          },
          {
            image: require(`@/assets/img/16-GAMES/G62-vocab-cards/lesson-54/card-3-1.svg`),
            imgShow: require(`@/assets/img/16-GAMES/G62-vocab-cards/lesson-54/card-3-2.svg`),
            Showing:false,
          },
           {
            image: require(`@/assets/img/16-GAMES/G62-vocab-cards/lesson-54/card-4-1.svg`),
            imgShow: require(`@/assets/img/16-GAMES/G62-vocab-cards/lesson-54/card-4-2.svg`),
            Showing:false,
          },
        ],
        [
          {
            image: require(`@/assets/img/16-GAMES/G62-vocab-cards/lesson-54/card-5-1.svg`),
            imgShow: require(`@/assets/img/16-GAMES/G62-vocab-cards/lesson-54/card-5-2.svg`),
            Showing:false,
          },
          {
            image: require(`@/assets/img/16-GAMES/G62-vocab-cards/lesson-54/card-6-1.svg`),
            imgShow: require(`@/assets/img/16-GAMES/G62-vocab-cards/lesson-54/card-6-2.svg`),
            Showing:false,
          },
          {
            image: require(`@/assets/img/16-GAMES/G62-vocab-cards/lesson-54/card-7-1.svg`),
            imgShow: require(`@/assets/img/16-GAMES/G62-vocab-cards/lesson-54/card-7-2.svg`),
            Showing:false,
          },
           {
            image: require(`@/assets/img/16-GAMES/G62-vocab-cards/lesson-54/card-8-1.svg`),
            imgShow: require(`@/assets/img/16-GAMES/G62-vocab-cards/lesson-54/card-8-2.svg`),
            Showing:false,
          },
      ],
      ]
    };
  },
  methods: {
    changeTheImg(index, currentIndex) {
      this.wordCardList[currentIndex][index].Showing = !this.wordCardList[currentIndex][index].Showing
    },
  },
};
</script>
<style lang="scss" scoped>
.wordscard-container {
  width: calc(100% - 20px);
  height: calc(100% - 40px);
  &.chapter-wordcard {
    background-color: #fff;
  }
}
</style>
